<!--
 This one no longer use. We use dialog one under components to replace this one
-->
<template>
  <v-container
    id="regular-tables-view"
    fluid
  >
    <v-row v-if="itemInfoUpdate">
      <property-create-edit
        ref="propertyCreateEdit"
        :item-id="itemId"
        @itemPropertyRefresh="loadItemProperties(itemId)"
      />
      <ecommerce-id-create-edit
        ref="ecommerceIdCreateEdit"
        @updateEcommerceIds="eCommerceIdsUpdate"
      />
      <snack-bar />
      <breadcrumbs :items="breadcrumbsItems" />
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-title
            style="font-weight: bold"
            class="text-md-h5"
          >
            <v-icon
              small
              color="purple darken-2"
              class="mr-1"
            >
              mdi-dialpad
            </v-icon>
            {{ editItem.part_number }}
          </v-card-title>
          <v-card-text>
            Description: {{ editItem.description }}
            <br>
            SKU: {{ editItem.sku_sos }}
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="eCommerceIdsTableHeader"
              :items="ecommerceIds"
              item-key="subId"
              :search="searchEcommerceId"
              dense
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>e-Commerce Ids</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  />
                  <v-text-field
                    v-model="searchEcommerceId"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  />
                  <v-spacer />
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="editEcommerceId(-1, null, editItem.id)"
                  >
                    Create New
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.is_active="{ item }">
                {{ getBooleanString(item.is_active) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editEcommerceId(item.id, item.table_name, editItem.id)"
                >
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col
            cols="12"
            class="mt-2"
          >
            <v-card>
              <v-card-title>
                Description and Pictures Management
              </v-card-title>
              <long-description-edit
                :item="editItem"
                @update-itemInfo="updateItemInfoFromChild"
              />
              <v-divider class="mx-3" />
              <image-edit :item-id="editItem.id" />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="my-2"
          >
            <category-create-edit
              ref="categoryCreateEdit"
              :item-category-id="editItem.item_category ? editItem.item_category.id : null"
              :item-id="editItem.id"
              @update-itemInfo="updateItemInfoFromChild"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="my-2"
          >
            <type-create-edit
              :item-id="editItem.id"
              :item-type-id="editItem.item_type ? editItem.item_type.id : null"
              @update-itemInfo="updateItemInfoFromChild"
            />
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            Properties Management
          </v-card-title>
          <v-card-text>
            <v-chip
              v-for="(property, key) in itemProperties"
              :key="key"
              class="ma-2"
              color="cyan"
              label
              text-color="white"
              @click="editCurrentProperty(property.id)"
            >
              <v-icon left>
                mdi-label
              </v-icon>
              {{ property.name }} : {{ property.value }}
            </v-chip>
            <v-chip
              v-for="(property, key) in itemNotOwnProperties"
              :key="key"
              class="ma-2"
              color="grey"
              label
              text-color="white"
              @click="editNoOwnProperty(property)"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ key }}
            </v-chip>
            <v-chip
              class="ma-2"
              color="primary"
              label
              text-color="white"
              @click="createNewProperty()"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Create New
            </v-chip>
          </v-card-text>
          <v-card-text>
            * Click Label To Edit
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Breadcrumbs from '@/layouts/default/widgets/Breadcrumbs'
  import SnackBar from '@/components/app/SnackBar'
  import MaterialCard from '@/components/MaterialCard'
  // Components
  import PropertyCreateEdit from '@/components/item/PropertyCreateEdit'
  import CategoryCreateEdit from '@/components/item/CategoryCreateEdit'
  import TypeCreateEdit from '@/components/item/TypeCreateEdit'
  import LongDescriptionEdit from '@/components/item/LongDescriptionEdit'
  import ImageEdit from '@/components/item/ImageEdit'
  import EcommerceIdCreateEdit from '@/components/app/EcommerceIdCreateEdit'
  // Api
  import ItemApi from '@/api/item'
  import ItemPropertyApi from '@/api/item-property'
  import ItemEcommerceApi from '@/api/item-ecommerce'
  export default {
    name: 'Management',
    components: {
      MaterialCard,
      Breadcrumbs,
      SnackBar,
      PropertyCreateEdit,
      CategoryCreateEdit,
      TypeCreateEdit,
      LongDescriptionEdit,
      ImageEdit,
      EcommerceIdCreateEdit,
    },
    data () {
      return {
        breadcrumbsItems: [
          {
            text: 'Item Table',
            disabled: false,
            to: { name: 'Item Receipt' },
          },
        ],
        // Data
        itemId: this.$route.params.id,
        propertyId: -1,
        editItem: {},
        eCommerceIdsTableHeader: [
          { text: 'eCommerce Id', align: 'start', value: 'value' },
          { text: 'Type', value: 'table_name' },
          { text: 'In Use', value: 'is_active' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        ecommerceIds: [],
        searchEcommerceId: '',
        itemProperties: [],
        itemNotOwnProperties: [],
        // function control
        itemInfoUpdate: false,
        newEggIdUpdate: false,
        ebayIdUpdate: false,
      }
    },
    created () {
      this.loadItemInfo(this.$route.params.id)
    },
    mounted () {
      this.loadItemProperties(this.itemId)
    },
    methods: {
      // Data Load
      loadItemInfo (id) {
        ItemApi.show(id).then(response => {
          this.editItem = response.data.data
          this.breadcrumbsItems.push(
            {
              text: this.editItem.part_number,
              disabled: true,
              to: { name: 'Item Receipt' },
            },
          )
          if (this.editItem.ecommerce_ids != null) {
            this.editItem.ecommerce_ids.forEach((element, key) => { element.subId = key + 1 })
            this.ecommerceIds = this.editItem.ecommerce_ids
          }
          this.itemInfoUpdate = true
        })
      },
      loadItemProperties (id) {
        ItemPropertyApi.index(id).then(response => {
          this.itemProperties = response.data.data
        })
        this.loadNotOwnProperties(this.itemId)
      },
      loadNotOwnProperties (itemId) {
        ItemPropertyApi.sameCategoryNoOwn(itemId).then(response => {
          this.itemNotOwnProperties = response.data.data
        })
      },
      updateItemInfoFromChild (itemInfo) {
        this.editItem = itemInfo
      },
      getBooleanString (data) {
        return data === 1 ? 'Yes' : 'No'
      },
      // Data create and modify
      createNewProperty () {
        this.$refs.propertyCreateEdit.activeDialog(-1)
      },
      editCurrentProperty (propertyId) {
        this.$refs.propertyCreateEdit.activeDialog(propertyId)
      },
      editNoOwnProperty (propertyList) {
        this.$refs.propertyCreateEdit.activeDialog(-1, propertyList)
      },
      editEcommerceId (id, tableName, itemId) {
        this.$refs.ecommerceIdCreateEdit.activeDialog(id, tableName, itemId)
      },
      eCommerceIdsUpdate () {
        ItemApi.show(this.itemId).then(response => {
          if (response.data.data.ecommerce_ids != null) {
            response.data.data.ecommerce_ids.forEach((element, key) => { element.subId = key + 1 })
            this.ecommerceIds = response.data.data.ecommerce_ids
          }
        })
      },
    },
  }
</script>

<style scoped>

</style>
