<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>
<script>
  export default {
    name: 'Breadcrumbs',
    props: {
      items: {
        type: Array,
      },
    },
  }
</script>

<style scoped>

</style>
